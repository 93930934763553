.IMG_coding{
    max-width: 400px;
}
.steckbrief {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.steckbrief h2 {
    margin-bottom: 20px;
}
.steckbrief .list-group-item {
    border: 0;
    border-bottom: 1px solid #ddd;
}