.DashboardCont{
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.DashboardImgCont{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.dashboardIMG{
    max-width: 800px;
    object-fit: cover;
    transition: transform 0.5s ease, opacity 0.5s ease;
}
.DashboardTextCont{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.DashboardTitelText{
    position: relative;
    transform: translateX(50%);
    transition: transform 0.5s ease;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
}

.divider{
    color: #454748;
    border: solid;
}

.AboutMeSection {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AboutMeTextCont {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.5s ease;
}

.AboutMeImgCont {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.aboutMeIMG {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BlogSection {
    padding: 50px 0;
}

.blog-post {
    margin-bottom: 30px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.blog-post-inner {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.blog-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.blog-img:hover {
    transform: scale(1.05);
}

.blog-title {
    text-align: center;
    padding: 15px;
    color: #646769;
}
a:link, a:visited {
    text-decoration: none;
}
