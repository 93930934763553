.Logo {
    max-width: 40px;
    display: block;
    padding-top: 10px;
}

.textColor {
    color: #657330;
}

.backcolor {
    background-color: #646769;
}

.footerheight {
    height: 70px;
}

.dividerLine {
    color: #454748;
    margin-top: 5px;
    height: 3px;
    border: solid
}

p {
    margin: 0;
}

#navbar_margin{
    margin-top: 30px;
}