#Einleitung_Blog_header {
    color: #343a40;
    margin-bottom: 20px;
}

#Einleitung_Blog_text {
    font-size: 1.2rem;
    color: #6c757d;
}

.bg-light {
    background-color: #f8f9fa !important;
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
h2 {
    margin-bottom: 20px;
}

.text-muted {
    font-size: 0.9rem;
    color: #6c757d;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.rounded {
    border-radius: 0.25rem;
}
.timeline-container {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline {
    list-style: none;
    padding: 0;
    position: relative;
}

.timeline-item {
    margin-bottom: 2rem;
    position: relative;
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1rem;
    width: 6px;
    height: 100%;
    background: #657330;
    border-radius: 6px;
}

.timeline-date {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.timeline-title {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: #657330;
}

.timeline-description {
    font-size: 1rem;
    color: #666;
}

.timeline-link {
    font-size: 1rem;
    color: black;
    text-decoration: none;
}

.timeline-link:hover {
    text-decoration: underline;
}
#blogIMG_size{
    max-width: 650px;
    max-height: 650px;
    margin: 0 auto;
}
.carousel-inner img {
    max-width: 100%;
    max-height: 650px;
    width: auto;
    height: auto;
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
